<template>
  <div class="bg-white h-full flex justify-center">
    <div class="w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />

      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />

        <span class="text-2xl font-semibold mb-6 text-bb-text-default">Set up a Site</span>

        <p class="text-bb-text-secondary mb-6">
          Begin with creating a Site. You will be able to create more Sites later.
        </p>
      </div>
      <form
        class="w-390 mx-auto pb-20"
        @keydown.prevent.enter="submit"
      >
        <div class="pb-4">
          <p class="text-sm font-medium">Where do you sell your products or services?</p>
        </div>

        <div class="mb-6 flex justify-between">
          <base-radio-button
            v-for="option in options"
            :key="option.value"
            :option="option"
            :option-label="() => option.value"
            :option-id="() => option.value"
            :option-value="() => option.value"
            :checked="option.value == siteForm.websiteOrAmazon"
            @input="
              val => {
                siteForm.websiteOrAmazon = val
                onSiteTypeChange()
              }
            "
          >
            <template #label>
              <component :is="option.icon" /> <span class="ml-2 text-bb-text-default">{{ option.label }}</span>
            </template>
          </base-radio-button>
        </div>

        <div
          v-if="siteForm.websiteOrAmazon"
          class="pb-12 space-y-6"
        >
          <url-input
            v-model="siteForm.siteUrl"
            class="w-full"
            :label="dynamicLabel"
            input-type="text"
            input-name="siteUrl"
            input-id="siteUrl"
            placeholder="Website"
            :error-message="$v.siteForm.siteUrl.$error ? 'Please enter a URL' : null"
            @focusout="$v.siteForm.siteUrl.$touch"
          />

          <text-input
            v-model="siteForm.siteName"
            class="w-full"
            label="Site Name"
            input-type="text"
            input-name="siteName"
            input-id="siteName"
            placeholder="Site Name"
            :error-message="$v.siteForm.siteName.$error ? 'Please enter a site name' : null"
            @focusout="$v.siteForm.siteName.$touch"
          />
          <search-input
            ref="countryInput"
            v-model="siteForm.siteCountry"
            input-id="country"
            input-name="country"
            label="Country"
            placeholder="Select Country"
            tooltip
            tooltip-text="The main country is where most, if not all, of the site's activity occurs. This country is the target for both search and shopping campaigns"
            :options="countryOptions"
            :error-message="$v.siteForm.siteCountry.$error ? 'Please enter a site country' : null"
            @select-item="onCountryChange"
            @focusout="$v.siteForm.siteCountry.$touch"
          />
          <search-input
            ref="languageInput"
            v-model="siteForm.siteLanguage"
            input-id="language"
            input-name="language"
            label="Main Language"
            tooltip
            tooltip-text="The main language is used in the product feed and is the language you must use to describe your business in the next step."
            :placeholder="siteForm.siteLanguage ? siteForm.siteLanguage.label : 'Select language'"
            :options="languageOptions"
            :error-message="$v.siteForm.siteLanguage.$error ? 'Please enter a language' : null"
            @select-item="updateLanguage"
            @focusout="$v.siteForm.siteLanguage.$touch"
          />
        </div>

        <div class="flex justify-between space-x-16">
          <MergeButtonRound
            button-type="secondary"
            class="flex"
            @click="back"
          >
            <div class="flex"><ic-chevron-left /> Previous</div>
          </MergeButtonRound>

          <MergeButtonRound
            brightbid
            type="submit"
            :disabled="isLoading"
            @click="submit"
          >
            <div class="flex">Next <ic-chevron-right /></div>
          </MergeButtonRound>
        </div>
        <div
          v-if="siteForm.websiteOrAmazon"
          class="p-4 rounded-lg flex items-start mt-12 bg-bb-background-purple-0"
        >
          <div class="pt-1">
            <icInfo class="my-auto cursor-pointer text-bb-brand-purple" />
          </div>
          <div
            v-if="siteForm.websiteOrAmazon === 'website'"
            class="pl-4 text-sm text-bb-purple-600"
          >
            <span class="font-medium mb-2">What’s a Site? </span>
            <p>
              A “Site” represents a single website within an Organization. If a company has multiple websites, like ones
              serving different countries or brands, a separate Site should be created for each.
            </p>
          </div>
          <div
            v-if="siteForm.websiteOrAmazon === 'amazon'"
            class="pl-4 text-sm text-bb-purple-600"
          >
            <span class="font-medium mb-2">Are you selling on multiple markets on Amazon?</span>
            <p>
              If you are selling on multiple markets, you need to add multiple Sites, one Site per market. Make sure the
              Site URL match the storefront or listing page for the market you want to advertise. You can add more Sites
              later.
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'

import BaseRadioButton from '@/components/input/base/BaseRadioButton'
import icInfo from 'vue-material-design-icons/InformationOutline'
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import icMyWebsite from '@/components/icon/ic-my-website'
import icAmazonLetterLogo from '@/components/icon/ic-amazon-letter-logo'
import TextInput from '@/components/input/brightbid/TextInput'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import UrlInput from '@/components/input/brightbid/URLInput'
import { mapActions, mapState, mapGetters } from 'vuex'
import SearchInput from '@/components/input/brightbid/SearchInput'

export default {
  name: 'CreateSiteInitial',
  components: {
    icInfo,
    IcChevronLeft,
    IcChevronRight,
    icMyWebsite,
    icAmazonLetterLogo,
    TextInput,
    HorizontalStepper,
    BaseRadioButton,
    UrlInput,
    SearchInput,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      steps: ['Org setup', 'Site setup', 'Connections'],
      activeStepIndex: 1,
      siteForm: {
        websiteOrAmazon: null,
        siteUrl: null,
        siteName: null,
        siteCountry: null,
        siteLanguage: null,
      },
      options: [
        {
          label: 'My Website',
          value: 'website',
          icon: 'ic-my-website',
        },
        {
          label: 'Amazon',
          value: 'amazon',
          icon: 'ic-amazon-letter-logo',
        },
      ],
      isLoading: false,
    }
  },
  computed: {
    ...mapState('country', {
      allCountries: 'countries',
      countryLoadingStatus: 'loading',
      countryErrorStatus: 'error',
    }),
    ...mapState('language', {
      allLanguages: 'languages',
      languageLoadingStatus: 'loading',
      languageErrorStatus: 'error',
    }),
    ...mapGetters('country', {
      formattedAmazonMarkets: 'formattedAmazonMarkets',
    }),
    countryOptions() {
      if (this.siteForm && this.siteForm.websiteOrAmazon === 'amazon') {
        return this.formattedAmazonMarkets
      }
      return this.allCountries
    },
    languageOptions() {
      if (this.siteForm && this.siteForm.websiteOrAmazon === 'amazon' && this.siteForm.siteCountry) {
        const selectedCountry = this.formattedAmazonMarkets.find(
          country => country.value === this.siteForm.siteCountry.value,
        )
        return selectedCountry ? selectedCountry.languages : []
      }
      return this.allLanguages
    },
    dynamicLabel() {
      if (this.siteForm.websiteOrAmazon === 'amazon') {
        return 'Amazon Storefront or Product Listing Page'
      }
      return 'Website'
    },
  },
  validations() {
    return {
      siteForm: {
        websiteOrAmazon: { required },
        siteUrl: { required, url },
        siteName: { required },
        siteCountry: { required },
        siteLanguage: { required },
      },
    }
  },
  async mounted() {
    await this.fetchCountries()
    await this.fetchAmazonAvailableMarketsData()
    await this.fetchLanguages()
    this.siteForm.websiteOrAmazon = this.context.isWebsite ? 'website' : 'amazon'
    if (this.context.isWebsite === null) this.siteForm.websiteOrAmazon = null

    this.siteForm.siteUrl = this.context.siteURL
    this.siteForm.siteName = this.context.siteName
    this.siteForm.siteCountry = this.context.siteCountry
    this.siteForm.siteLanguage = this.context.siteLanguage
  },
  methods: {
    back() {
      if (this.context.desiredState) return
      this.$emit('prev')
    },
    async submit() {
      this.$v.siteForm.$touch()
      if (this.$v.siteForm.$invalid || this.isLoading) return

      const payload = {
        name: this.siteForm.siteName,
        url: this.siteForm.siteUrl,
        type: 'standard',
        organization_id: this.siteForm.organizationID,
        country_code: this.siteForm.siteCountry.value,
        language_code: this.siteForm.siteLanguage.value,
      }

      if (!this.context.siteID) {
        // The site has not been created yet
        this.$emit('next', { ...this.siteForm })
      } else {
        // The site has been created, patch
        try {
          await this.$http.patch(`/common/site/${this.context.siteID}/general`, {
            name: payload.name,
            url: payload.url,
            type: payload.type,
            organization_id: payload.organization_id,
            country_code: payload.country_code,
            language_code: payload.language_code,
          })
        } catch (error) {
          console.log(context)
        }
        this.$emit('next', { ...this.siteForm })
      }
    },
    onCountryChange(newCountry) {
      if (this.siteForm.siteCountry !== newCountry) {
        this.siteForm.siteLanguage = null
        this.$refs.languageInput.selectedItem = null
        this.$refs.languageInput.searchInput = null
      }
      this.siteForm.siteCountry = newCountry
      if (this.siteForm.websiteOrAmazon !== 'amazon') return

      const selectedCountry = this.formattedAmazonMarkets.find(
        country => country.value.toLowerCase() === newCountry.value.toLowerCase(),
      )
      if (selectedCountry && selectedCountry.languages) {
        this.siteForm.siteLanguage = selectedCountry.languages[0]
        this.$refs.languageInput.selectedItem = selectedCountry.languages[0]
        this.$refs.languageInput.searchInput = selectedCountry.languages[0].label
      }
    },
    updateLanguage(newLanguage) {
      this.siteForm.siteLanguage = newLanguage
    },
    clearCountryAndLanguage() {
      this.siteForm.siteCountry = null
      this.siteForm.siteLanguage = null

      if (!this.$refs.countryInput || !this.$refs.languageInput) return
      this.$refs.countryInput.selectedItem = null
      this.$refs.countryInput.searchInput = null
      this.$refs.languageInput.selectedItem = null
      this.$refs.languageInput.searchInput = null
    },
    onSiteTypeChange() {
      this.clearCountryAndLanguage()
    },
    ...mapActions('country', ['fetchCountries', 'fetchAmazonAvailableMarketsData']),
    ...mapActions('language', ['fetchLanguages']),
  },
}
</script>
