<template>
  <div class="bg-white h-full flex justify-center">
    <div class="w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />

      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />
        <span class="text-2xl font-semibold mb-6 text-bb-text-default">Your Site is now ready! </span>
      </div>

      <form class="w-500 mx-auto pb-20">
        <div class="pb-4">
          <p class="text-sm font-normal">Set up a Channel now. You can always set up more later.</p>
        </div>

        <div>
          <!--AMAZON-->
          <channel-card
            v-if="!context.isWebsite"
            amazon
            :rounded="context.isWebsite ? '' : 'top'"
            :is-active="amazon"
            :is-onboarding="true"
            @connected="hasAConnectedAccount = true"
            @select-channel="selectChannel('amazon')"
          >
            <template #icon>
              <img
                src="/content/images/amazon-icon.svg"
                alt="AMAZON"
              />
            </template>
            <template #card-title> Amazon </template>
            <template #card-description> Connect to your Amazon Storefront </template>
          </channel-card>

          <!--GOOGLE ADS SEARCH-->
          <channel-card
            google-ads
            :rounded="context.isWebsite ? 'top' : ''"
            :is-active="googleAds"
            :is-onboarding="true"
            @connected="hasAConnectedAccount = true"
            @select-channel="selectChannel('googleAds')"
          >
            <template #icon>
              <img
                src="/content/images/g-ads-icon.svg"
                alt="G_ADS_SEARCH"
              />
            </template>
            <template #card-title> Google Ads </template>
            <template #card-description> Connect to your Google Ads account </template>
          </channel-card>

          <!--GOOGLE ADS SHOPPING-->
          <channel-card
            v-if="context.isWebsite"
            google-merchant-center
            rounded="bottom"
            :border="context.isWebsite ? 'top' : 'bottom'"
            :is-active="googleMerchantCenter"
            :is-onboarding="true"
            @connected="hasAConnectedAccount = true"
            @select-channel="selectChannel('googleMerchantCenter')"
          >
            <template #icon>
              <img
                src="/content/images/gmc-icon.svg"
                alt="G_ADS_SHOPPING"
              />
            </template>
            <template #card-title> Google Shopping </template>
            <template #card-description> Connect to your Merchant Center </template>
          </channel-card>
        </div>

        <!--BUTTONS-->
        <div class="w-390 mx-auto">
          <div class="flex justify-between mt-10">
            <MergeButtonRound
              button-type="secondary"
              @click="$emit('previous')"
            >
              <template #left-icon>
                <IcChevronLeft />
              </template>
              Previous
            </MergeButtonRound>
            <MergeButtonRound
              type="submit"
              :disabled="!hasAConnectedAccount"
              @click="$emit('next', context)"
            >
              <template #right-icon>
                <IcChevronRight />
              </template>
              Next
            </MergeButtonRound>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import ChannelCard from '@/components/onboard-components/ChannelCard'

export default {
  name: 'SetupConnections',
  components: {
    IcChevronLeft,
    IcChevronRight,
    HorizontalStepper,
    ChannelCard,
  },
  provide() {
    return {
      context: this.context,
    }
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amazon: false,
      googleAds: false,
      googleMerchantCenter: false,
      steps: ['Org setup', 'Site setup', 'Connections'],
      activeStepIndex: 2,
      hasAvailableGoogleStore: false,
      isLoading: false,
      hasAConnectedAccount: false,
    }
  },
  methods: {
    selectChannel(channel) {
      // this will close the channel when it is open.
      if (this[channel]) {
        this[channel] = !this[channel]
        return
      }

      this.amazon = false
      this.googleAds = false
      this.googleMerchantCenter = false

      this[channel] = true
    }
  },
}
</script>
