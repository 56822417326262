<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="text-center w-full space-y-4 pb-40">
      <img
        src="/content/images/brightbid/colored-brightbid-logo-only.svg"
        class="mx-auto"
        alt="Brightbid"
      />
      <h1 class="h1a">What's your next move?</h1>
      <div class="text-bb-text-secondary pb-16">
        <p>Go to your organizations or add a new one</p>
      </div>
      <div class="mx-auto max-w-sm w-full flex flex-col gap-6 mt-2">
        <OrganizationButton @next="viewOrganizations">
          <template #left-icon>
            <ic-skyscraper :size="40" />
          </template>
          <template #text="slotProps">
            <p :class="[slotProps.isActive ? 'text-white' : 'text-black', 'font-medium']">View your Organizations</p>
            <p class="p1">Total: {{ userOrganizationLength }} organizations</p>
          </template>
          <template #right-icon>
            <ic-arrow
              :size="40"
              class="transform rotate-45"
            />
          </template>
        </OrganizationButton>
        <OrganizationButton @next="next">
          <template #text="slotProps">
            <p :class="[slotProps.isActive ? 'text-white' : 'text-black', 'font-medium']">Create an Organization</p>
          </template>
          <template #left-icon>
            <ic-plus :size="40" />
          </template>
        </OrganizationButton>
      </div>
    </div>
  </div>
</template>
<script>
import IcPlus from '@/components/icon/ic-plus'
import IcArrow from '@/components/icon/ic-arrow'
import IcSkyscraper from '@/components/icon/ic-skyscraper'
import OrganizationButton from '@/components/onboard-components/OrganizationButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'ViewOrCreateOrgsStep',
  components: {
    IcPlus,
    IcSkyscraper,
    IcArrow,
    OrganizationButton,
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('organization', ['userOrganization']),
    userOrganizationLength() {
      if (!this.userOrganization) return 0
      return this.userOrganization.length
    },
  },
  methods: {
    next() {
      this.$emit('next')
    },
    viewOrganizations() {
      this.$router.push({ name: 'settings:organization' })
    },
  },
}
</script>
