<template>
  <div class="bg-white h-full flex justify-center">
    <div class="md:w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />

      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />
        <span class="text-2xl font-semibold mb-6 text-bb-text-default">Set up your Organization</span>
        <p class="text-bb-text-secondary mb-6">
          Get started with your first Organization. You can always add more later.
        </p>
      </div>

      <form
        class="w-390 mx-auto"
        @keydown.prevent.enter="submit"
      >
        <div class="mb-10">
          <text-input
            v-model="organizationForm.name"
            class="w-full"
            label="Organization Name"
            sub-label="Is typically the name of your company"
            input-type="text"
            input-name="name"
            input-id="name"
            placeholder="Organization Name"
            :error-message="$v.organizationForm.name.$error ? 'Please enter a name' : null"
            @focusout="$v.organizationForm.name.$touch"
          />
        </div>

        <div class="flex justify-between space-x-16">
          <MergeButtonRound
            button-type="secondary"
            class="flex"
            @click="$emit('prev')"
          >
            <template #left-icon>
              <IcChevronLeft />
            </template>
            Previous
          </MergeButtonRound>
          <MergeButtonRound
            brightbid
            type="submit"
            @click="submit"
          >
            <template #right-icon>
              <IcChevronRight />
            </template>
            Next
          </MergeButtonRound>
        </div>

        <div class="p-4 bg-white rounded-lg flex items-start mt-32 bg-bb-background-purple-0">
          <div class="pt-1">
            <icInfo class="my-auto cursor-pointer text-bb-purple-600" />
          </div>

          <div class="pl-4 text-sm text-bb-purple-600">
            <span class="font-medium mb-2">What's an Organization?</span>
            <p>
              An "Organization" is where you'll find the overall information and activities related to your company.
              Such as how each platform that you're advertising on is performing, as well as user and payment details.
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import icInfo from 'vue-material-design-icons/InformationOutline'
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import TextInput from '@/components/input/brightbid/TextInput'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import Toast from '@/components/shared/Toast'
import { PERMISSIONS, RESOURCES } from '../../core/constants'

export default {
  name: 'CreateOrganization',
  components: {
    icInfo,
    IcChevronLeft,
    IcChevronRight,
    TextInput,
    HorizontalStepper,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      steps: ['Org setup', 'Site setup', 'Connections'],
      activeStepIndex: 0,
      organizationForm: {
        name: null,
        organizationID: null,
      },
      isAllowedInvite: false,
    }
  },
  async mounted() {
    this.organizationForm.name = this.context.organizationName
    this.organizationForm.organizationID = this.context.organizationID

    this.isAllowedInvite = await this.isAllowed({
      resourceSlug: RESOURCES.USER_INVITE,
      permissionSlug: PERMISSIONS.ADD,
    })
  },
  validations() {
    return {
      organizationForm: {
        name: { required },
      },
    }
  },
  methods: {
    showToast(title, message, type) {
      this.$toast.error({
        component: Toast,
        props: {
          title: title,
          message: message,
          type: type,
        },
      })
    },
    async submit() {
      this.$v.organizationForm.$touch()
      if (this.$v.organizationForm.$invalid || this.isLoading) return

      this.isLoading = true

      if (!this.organizationForm.organizationID) {
        // The organization name has not been created yet
        try {
          if (!this.context.organizationID) {
            const resp = await this.$http.post('/common/organization', {
              name: this.organizationForm.name,
            })
            this.organizationForm['organizationID'] = resp.data.id
          }
        } catch (error) {
          console.log(error)
          this.showToast('Error', 'Failed to create an organization.', 'error')
        }
      } else if (this.organizationForm.name !== this.context.organizationName) {
        // Update the organization name
        try {
          await this.$http.patch(`common/organization/${this.organizationForm.organizationID}`, {
            name: this.organizationForm.name,
          })
        } catch (error) {
          console.log(error)
          this.showToast('Error', 'Failed to update the organization name.', 'error')
        }
      }

      this.isLoading = false
      this.$emit('next', { ...this.organizationForm })
    },
  },
}
</script>
