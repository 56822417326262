<template>
  <nav
    aria-label="Progress bar"
    class="flex justify-center pt-8 pb-16"
  >
    <ol
      role="list"
      class="flex items-center"
    >
      <li
        v-for="(step, index) in steps"
        :key="index"
        class="relative"
        :class="{ 'pr-8 sm:pr-32': steps.length - 1 !== index }"
      >
        <div
          v-if="steps.length - 1 !== index"
          class="absolute inset-0 flex items-center"
          aria-hidden="true"
        >
          <div :class="index < activeStepIndex ? 'h-0.5 w-full bg-indigo-600' : 'h-0.5 w-full bg-gray-400'" />
        </div>

        <div
          :class="
            index <= activeStepIndex
              ? 'relative flex h-2 w-2 rounded-full bg-indigo-600'
              : 'group relative flex h-2 w-2 rounded-full border-2 border-gray-400 bg-gray-400'
          "
        />
        <div
          class="absolute font-normal"
          :class="{
            'text-gray-400': index > activeStepIndex,
            'text-black font-bold': index === activeStepIndex,
          }"
          style="left: -30px; top: 16px"
        >
          {{ step }}
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'HorizontalStepper',
  props: {
    activeStepIndex: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array, // Array<String>
      required: true,
    },
  },
}
</script>

<style></style>
